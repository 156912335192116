/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "palsie/schuur2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "palsie/schuur2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(relativePath: { eq: "palsie/pals4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `M.Pals - Schuurplafond`,
  textWithLink: () => (
    <>
      <p>
        Bij een schuurplafond wordt het plafond voorzien van een laag mortel die
        afgewerkt wordt met een draaiende schaduwwerking. Dit schuurwerk leidt
        tot gelijkmatige draaiingen die goed zichtbaar worden bij een bepaalde
        lichtinval.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0629575549'>
          <Button
            role='button'
            variant='contained'
            className='btn heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const textBottom = {
  textWithLink: () => (
    <>
      <AniLink fade to='/plafondafwerking'>
        <Button
          role='button'
          variant='contained'
          className='btn heading__button'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Schuurplafond = ({ data, path, location }) => {
  const seo = {
    title: `Schuurplafond`,
    description: `Geef je huis net een beetje extra design met een prachtige schuurplafond.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'M.Pals Stukadoors',
    imgTitle: 'M.Pals Stukadoors',
    header: 'M.Pals - Schuurplafond',
    textWithLink: () => (
      <>
        <p>
          Bij een schuurplafond wordt het plafond voorzien van een laag mortel.
          Deze laag wordt afgewerkt met een draaiende schaduwwerking. Dit
          schuurwerk leidt tot gelijkmatige draaiingen die goed zichtbaar worden
          bij een bepaalde lichtinval.
        </p>
        <p>Kenmerken:</p>
        <ul>
          <li>Het is warmte-isolerend en brandvertragend</li>
          <li>
            Het is niet stootvast waardoor reparaties achteraf altijd zichtbaar
            blijven
          </li>
          <li>Het droogt egaal op zodat schilderen of sauzen niet nodig is</li>
          <li>
            Het heeft een groot ademend vermogen en is daardoor zeer geschikt
            voor natte ruimtes
          </li>
          <li>Uitstraling verandert door lichtinval</li>
        </ul>
      </>
    ),
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Schuurplafond`,
    pitchLine: `Geef je huis net een beetje extra design met een prachtige schuurplafond.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        textBottom={textBottom}
      />
    </>
  );
};

Schuurplafond.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Schuurplafond;
